import React from 'react';

import { MenuItem } from '@/types/Menu';
import LinkTo from '@/components/LinkTo';

interface Props {
  item: MenuItem;
  toggleOpen: () => void;
}

const Highlights: React.FC<Props> = ({ item, toggleOpen }) => {
  return (
    <ul className="space-y-3">
      {item.highlights?.map((highlight, idx) => (
        <li key={idx}>
          {highlight.href && (
            <LinkTo
              href={highlight.href}
              onClick={toggleOpen}
              className="font-bold"
              aria-label={highlight.name}
            >
              {highlight.name}
            </LinkTo>
          )}
        </li>
      ))}
    </ul>
  );
};

export default Highlights;
