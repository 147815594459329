import React, { Fragment, useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import { newsletterSignup, newsletterSignupFull } from '@/fetchers/newsletter';
import { useAlert } from '@/context/AlertProvider';
import { getLangCountry } from '@/helpers/languages';
import { useCookies } from 'react-cookie';
import { Button } from '../../UI/Button';
import { ArrowRightIcon, XIcon } from '@heroicons/react/solid';
import Image from 'next/future/image';

import Input from '../../Forms/Input';
import Checkbox from '../../Forms/Checkbox';
import { Dialog, Transition } from '@headlessui/react';
import LinkTo from '@/components/LinkTo';
import { set } from 'lodash';

const NewsletterForm = () => {
  const [sheetOpen, setSheetOpen] = useState(false);
  const { t } = useTranslation();
  const router = useRouter();
  const { lang, country } = getLangCountry(router.query);
  const alert = useAlert();
  const [cookies] = useCookies(['uyn_bearer']);

  const formik = useFormik({
    initialValues: {
      email: '',
      privacy: false,
      name: '',
      surname: ''
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .matches(
          /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i,
          t('INVALID_EMAIL')
        )
        .required(t('REQUIRED_FIELD')),
      name: Yup.string().required(t('REQUIRED_FIELD')),
      surname: Yup.string().required(t('REQUIRED_FIELD')),
      privacy: Yup.boolean().oneOf([true]).required()
    }),
    async onSubmit({ email, name, surname }) {
      const { error } = await newsletterSignupFull({
        token: cookies['uyn_bearer'],
        email,
        lang,
        country,
        name,
        surname
      });

      if (error) {
        return alert.error(t('GENERIC_ERROR'));
      }
      setSheetOpen(false);
      formik.resetForm();

      alert.show(t('NEWSLETTER_THANK_YOU'));
    }
  });

  return (
    <div className="space-y-8">
      <div
        onClick={() => setSheetOpen(open => !open)}
        className="flex space-x-4 items-center cursor-pointer"
      >
        <div className="space-y-1">
          <h3 className="h3">Newsletter Uyn</h3>
          <p>Per te il 20% di sconto sul tuo primo acquisto</p>
        </div>
        <button
          className="inline-block border text-center uppercase space-x-1 bg-white text-black p-2 rounded-full md:p-4 disabled:bg-gray-100 cursor-pointer h-max"
          type="submit"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
            className="w-4 h-4"
          >
            <path
              fillRule="evenodd"
              d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
              clipRule="evenodd"
            ></path>
          </svg>
        </button>
      </div>

      <Transition show={sheetOpen} as={Fragment}>
        <Dialog onClose={setSheetOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black/30" />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="transition-all ease-out duration-500"
            enterFrom="transform translate-x-full opacity-50"
            enterTo="transform translate-x-0 opacity-100"
            leave="transition-all ease-in duration-500"
            leaveFrom="transform translate-x-0 opacity-100"
            leaveTo="transform translate-x-full opacity-50"
          >
            <Dialog.Panel className="w-full fixed right-0 z-50 inset-y-0 bg-white shadow-lg sm:max-w-md overflow-y-auto">
              <div className="section relative full p-0">
                <Image
                  src="/v2/subscribe/header-uyn-newsletter_dks.jpg"
                  alt=""
                  width={0}
                  height={0}
                  priority
                  sizes="100vw"
                  className="block w-full  h-full object-cover object-center"
                />
                <button
                  className="flex absolute top-4 right-4 justify-center items-center w-8 h-8 bg-white rounded-full"
                  onClick={() => setSheetOpen(open => !open)}
                >
                  <XIcon className="w-4 h-4 text-primary" />
                </button>
              </div>

              <div className="section p-8">
                <div className="section-wrapper relative max-w-[720px] mx-auto space-y-10">
                  <div className="text-body">{t('P_SUBSCRIBE.CONTENT')}</div>
                  <form className="space-y-4" onSubmit={formik.handleSubmit}>
                    <div className="space-y-6 lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-x-6">
                      <Input
                        type="text"
                        name="name"
                        label="Name"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        error={formik.errors.name}
                        required
                      />
                      <Input
                        type="text"
                        name="surname"
                        label="Surname"
                        value={formik.values.surname}
                        onChange={formik.handleChange}
                        error={formik.errors.surname}
                        required
                      />
                    </div>

                    <div className="space-y-6 lg:space-y-0 lg:grid lg:grid-cols-1 lg:gap-x-6">
                      <Input
                        type="text"
                        name="email"
                        label="Email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        error={formik.errors.email}
                        required
                      />
                    </div>

                    <Checkbox
                      name="privacy"
                      required
                      checked={formik.values.privacy}
                      onChange={formik.handleChange}
                      error={formik.errors.privacy}
                    >
                      <span>{t('DATA_CONSENT')} </span>
                      <span>
                        <LinkTo
                          href="/terms-and-conditions"
                          className="underline"
                          aria-label={t('DATA_CONSENT')}
                        >
                          {t('CLICK_HERE')}
                        </LinkTo>
                      </span>
                    </Checkbox>

                    <Button
                      type="submit"
                      variant="dark"
                      className="w-full"
                      loading={formik.isSubmitting}
                    >
                      {t('P_SUBSCRIBE.BTN')}
                    </Button>
                  </form>
                </div>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </Dialog>
      </Transition>

      {/* <div className="space-y-3">
        <h3 className="h3">{t('MENU.NEWSLETTER.TITLE')}</h3>

        <p>{t('MENU.NEWSLETTER.DESC')}</p>
      </div>

      <form className="space-y-2" onSubmit={formik.handleSubmit}>
        <div className="flex space-x-3">
          <Input
            type="email"
            name="email"
            placeholder="Email *"
            className="bg-transparent focus:ring-gray-400 focus:border-gray-400"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.errors.email}
            required
          />

          <Button
            loading={formik.isSubmitting}
            type="submit"
            variant="secondary"
            className="h-max"
          >
            <ArrowRightIcon className="w-4 h-4" />
          </Button>
        </div>

        <Checkbox
          name="privacy"
          checked={formik.values.privacy}
          onChange={formik.handleChange}
          error={formik.errors.privacy}
          className="bg-transparent border-gray-400 focus:ring-gray-400 focus:border-gray-400"
        >
          <span className="text-xs">{t('MENU.NEWSLETTER.PRIVACY')}</span>
          <a
            href={`https://www.iubenda.com/privacy-policy/${t(
              'IUBENDA.POLICY_ID'
            )}`}
            className="iubenda-white no-brand iubenda-noiframe iub-body-embed text-xs text-primary"
            title="Privacy Policy"
            target="_blank"
            rel="noreferrer"
          >
            {t('MENU.PRIVACY_POLICY')}
          </a>
        </Checkbox>
      </form> */}
    </div>
  );
};

export default NewsletterForm;
