import React, { Fragment, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { SearchIcon, XIcon } from '@heroicons/react/solid';
import { useDebouncedCallback } from 'use-debounce';
import { searchApi } from '../../fetchers/search';
import { getLangCountry } from '../../helpers/languages';
import { useRouter } from 'next/router';
import Spinner from '../Spinner';
import { useTranslation } from 'react-i18next';
import { IProductList } from '../../models';
import { fetchShippingCountriesList } from '../../fetchers/shipping';
import { getCurrencyName } from '../../helpers/prices';
import ProductCard from '../v2/Pages/Category/Products/ProductCard';
import { isEmpty } from 'lodash';
import LinkTo from '../LinkTo';
import { buttonVariants } from '../v2/UI/Button';
import { getToken } from '@/helpers/auth';

const MenuSearch: React.FC = () => {
  const token = getToken();
  const { t } = useTranslation();
  const router = useRouter();
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [hasMadeRequest, setHasMadeRequest] = useState(false);
  const [products, setProducts] = useState<IProductList[]>([]);
  const [count, setCount] = useState(0);
  const [offset, setOffset] = useState(0);
  const { lang, country } = getLangCountry(router.query);
  const countries = fetchShippingCountriesList({ lang });
  const currencyCode = getCurrencyName(countries, country);

  const handleSearch = async (concat: boolean) => {
    setLoading(true);

    if (!concat) {
      setProducts([]);
    }

    const response = await searchApi({
      search,
      lang,
      token,
      country,
      offset
    });

    setHasMadeRequest(true);
    setLoading(false);

    if (concat) {
      setProducts([...products, ...response.products]);
      trackPageViewEvent([...products, ...response.products]);
    } else {
      setProducts(response.products);
      trackPageViewEvent(response.products);
    }

    setCount(response.count);
  };

  const debouncedSearch = useDebouncedCallback(handleSearch, 800);

  useEffect(() => {
    setOffset(0);

    if (search.length > 2) {
      debouncedSearch(false);
    }
  }, [search]);

  useEffect(() => {
    if (offset !== 0) {
      handleSearch(true);
    }
  }, [offset]);

  const resetSearch = () => {
    setSearch('');
    setProducts([]);
    setCount(0);

    setTimeout(() => {
      setIsOpen(false);
    }, 300);
  };

  const close = () => {
    setTimeout(() => {
      setIsOpen(false);
    }, 300);
  };

  const trackPageViewEvent = (products: IProductList[]) => {
    const impressions = products.map((product: any, position) => ({
      id: product.selling_code,
      name: product.name,
      price: product.price?.price,
      brand: 'UYN',
      category: '',
      position,
      list: 'Search page'
    }));

    window.dataLayer?.push({
      ecommerce: {
        currencyCode,
        impressions
      }
    });
  };

  const trackClickEvent = (p: IProductList, position: number) => {
    window.dataLayer?.push({
      event: 'productClick',
      ecommerce: {
        currencyCode,
        click: {
          actionField: { list: 'search' },
          products: [
            {
              id: p.product_type + '-' + p.selling_code,
              name: p.name,
              price: p.price?.price,
              brand: 'UYN',
              category: '',
              position
            }
          ]
        }
      }
    });

    close();
  };

  return (
    <Fragment>
      <div className="relative flex">
        <button onClick={() => setIsOpen(true)} className="hover:text-gray-500">
          <span className="sr-only">{t('SEARCH')}</span>
          <SearchIcon className="w-6 h-6" aria-hidden="true" />
        </button>

        <Transition
          as={Fragment}
          show={isOpen}
          enter="transition ease-out duration-200"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition ease-in duration-50"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog as="div" className="z-10" onClose={resetSearch}>
            <Dialog.Overlay className="fixed top-0 left-0 right-0 bottom-0 w-screen h-screen bg-black/30" />

            <div className="absolute z-20 top-40 right-5 bottom-5 bg-white h-auto overflow-auto w-4/5 md:w-2/3 xl:w-1/2 p-4 xl:p-8 rounded">
              <div className="flex items-center justify-between mb-4 border-b border-gray-200">
                <input
                  type="text"
                  placeholder={t('MENU_SEARCH_PRODUCT')}
                  className="w-full border-0 px-0 text-base placeholder-gray-600 text-black focus:outline-none focus:ring-0"
                  value={search}
                  onChange={({ target }) => setSearch(target.value)}
                />
                <button className="m-0" onClick={resetSearch}>
                  <XIcon className="w-5 h-5 text-black" />
                </button>
              </div>

              {loading && (
                <div className="w-full text-center py-8">
                  <Spinner />
                </div>
              )}

              {hasMadeRequest && !loading && isEmpty(products) && (
                <div className="w-full py-4">{t('NO_SEARCH_RESULTS')}</div>
              )}

              {!isEmpty(products) && (
                <ul className="grid grid-cols-2 gap-y-10 gap-x-4 md:grid-cols-3">
                  {products.slice(0, 9).map((product, idx) => (
                    <ProductCard
                      key={idx}
                      data={product}
                      _onClick={() => trackClickEvent(product, idx)}
                    />
                  ))}
                </ul>
              )}

              {hasMadeRequest && !loading && count > 9 && (
                <div className="flex justify-center pt-10">
                  <LinkTo
                    href={`/search?query=${search}`}
                    onClick={close}
                    className={buttonVariants({ variant: 'dark' })}
                  >
                    {t('ALL_PRODUCTS')}
                  </LinkTo>
                </div>
              )}
            </div>
          </Dialog>
        </Transition>
      </div>
    </Fragment>
  );
};

export default MenuSearch;
