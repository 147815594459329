import axios from 'axios';
import { useRouter } from 'next/router';
import { useQuery } from '@tanstack/react-query';

import { DEFAULT_LANG, getLangCountry } from '@/helpers/languages';

import { MenuItem } from '@/types/Menu';
import { getAPIClient } from '@/lib/api';

export function useMenu() {
  const router = useRouter();
  const { lang, country } = getLangCountry(router.query);

  return useQuery(['menu', lang], async () => {
    let data: { [key: string]: MenuItem[] } = {};

    if (process.env.NODE_ENV === 'development') {
      const apiClient = getAPIClient({ country });

      const response = await apiClient.get<{
        menu: { [key: string]: MenuItem[] };
      }>('/advanced-api/api/menu');

      data = response.data.menu;
    } else {
      const urlMenu =
        country == 'us' ? '/menu/menu_us.json' : '/menu/menu.json';

      const response = await axios.get<{ [key: string]: MenuItem[] }>(urlMenu);

      data = response.data;
    }

    return data[lang] ? data[lang] : data[DEFAULT_LANG];
  });
}
