import React from 'react';
import Image from 'next/image';
import { ChatIcon } from '@heroicons/react/outline';
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaYoutube
} from 'react-icons/fa';
import packageInfo from '../../../../package.json';

import payWithIcon from '@/public/img/pay-with.svg';
import LinkTo from '@/components/LinkTo';
import { useTranslation } from 'react-i18next';
import NewsletterForm from './NewsletterForm';
import ChangeLangCountry from '@/components/ChangeLangCountry';
import { useRouter } from 'next/router';
import { getLangCountry } from '@/helpers/languages';

const POLICY_ID = 'IUBENDA.POLICY_ID';

const getFacebookLink = (country: string) => {
  switch (country) {
    case 'it':
      return 'https://www.facebook.com/uynsports.it';
    case 'fr':
      return 'https://www.facebook.com/uynsports.fr';
    case 'de':
    case 'at':
      return 'https://www.facebook.com/uynsports.de';
    case 'ch':
      return 'https://www.facebook.com/uynsports.ch';
    default:
      return 'https://www.facebook.com/uynsports.en';
  }
};

export default function Footer() {
  const router = useRouter();
  const { t } = useTranslation();
  const { lang, country } = getLangCountry(router.query);

  const showIubenda =
    process.env.ENV !== 'staging' && process.env.NEXT_PUBLIC_ENV !== 'staging';
  function openChatWidget() {
    if (window.LC_API && window.LC_API.is_loaded()) {
      window.LC_API.open_chat_window();
    }
  }

  const injectAvantLink = country === 'us';

  return (
    <footer className="bg-black text-white">
      <div className="section full pb-8">
        <div className="section-wrapper space-y-8 md:space-y-16">
          <div className="space-y-8 xl:space-y-0 flex flex-col justify-between md:flex-row flex-wrap">
            <div className="space-y-8">
              <NewsletterForm />

              <div className="flex flex-col space-y-4">
                <button
                  className="flex items-center space-x-2"
                  onClick={openChatWidget}
                >
                  <div className="flex justify-center items-center h-8 w-8 bg-white/20 rounded-full">
                    <ChatIcon className="h-4 w-4" />
                  </div>

                  <span>{t('MENU.CHAT')}</span>
                </button>

                <ChangeLangCountry
                  hideChevron
                  buttonClassName="w-full flex items-center"
                />
              </div>
            </div>

            <div className="space-y-8 md:justify-items-center">
              <ul className="flex flex-col space-y-3">
                <li className="text-2xl font-medium tracking-uyn uppercase">
                  <LinkTo href="/areas" aria-label={t('MENU.AREAS')}>
                    {t('MENU.AREAS')}
                  </LinkTo>
                </li>

                <li className="text-2xl font-medium tracking-uyn uppercase">
                  <LinkTo
                    href="/sustainability"
                    aria-label={t('MENU.SUSTAINABILITY')}
                  >
                    {t('MENU.SUSTAINABILITY')}
                  </LinkTo>
                </li>

                <li className="text-2xl font-medium tracking-uyn uppercase">
                  <a
                    href={
                      lang == 'en'
                        ? `https://blog.uynsports.com/`
                        : `https://blog.uynsports.com/${lang}`
                    }
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t('MENU.NEWS')}
                  </a>
                </li>

                <li className="text-2xl font-medium tracking-uyn uppercase">
                  <LinkTo
                    href="/store-locator"
                    aria-label={t('MENU.STORE_LOCATOR')}
                  >
                    {t('MENU.STORE_LOCATOR')}
                  </LinkTo>
                </li>
              </ul>

              <ul className="flex space-x-12">
                <li>
                  <a
                    href={getFacebookLink(country)}
                    target="_blank"
                    rel="noreferrer"
                    aria-label="Facebook"
                  >
                    <span className="sr-only">Facebook</span>
                    <FaFacebookF className="h-6 w-6" />
                  </a>
                </li>

                <li>
                  <a
                    href="https://www.instagram.com/uynsports"
                    target="_blank"
                    rel="noreferrer"
                    aria-label="Instagram"
                  >
                    <span className="sr-only">Instagram</span>
                    <FaInstagram className="h-6 w-6" />
                  </a>
                </li>

                <li>
                  <a
                    href="https://www.linkedin.com/showcase/uynsports"
                    target="_blank"
                    rel="noreferrer"
                    aria-label="LinkedIn"
                  >
                    <span className="sr-only">LinkedIn</span>
                    <FaLinkedinIn className="h-6 w-6" />
                  </a>
                </li>

                <li>
                  <a
                    href="https://www.youtube.com/@uyn9809"
                    target="_blank"
                    rel="noreferrer"
                    aria-label="YouTube"
                  >
                    <span className="sr-only">YouTube</span>
                    <FaYoutube className="h-6 w-6" />
                  </a>
                </li>
              </ul>
            </div>

            <div className="space-y-6">
              <h3 className="text-2xl font-medium tracking-uyn uppercase">
                {t('MENU.HELP')}
              </h3>

              <ul className="space-y-3">
                <li>
                  <LinkTo href="/shipping" aria-label={t('MENU.SHIPPING')}>
                    {t('MENU.SHIPPING')}
                  </LinkTo>
                </li>

                <li>
                  <LinkTo href="/return" aria-label={t('MENU.RETURNS')}>
                    {t('MENU.RETURNS')}
                  </LinkTo>
                </li>

                <li>
                  <LinkTo href="/payments" aria-label={t('MENU.PAYMENTS')}>
                    {t('MENU.PAYMENTS')}
                  </LinkTo>
                </li>

                <li>
                  <LinkTo href="/warranty" aria-label={t('MENU.WARRANTY')}>
                    {t('MENU.WARRANTY')}
                  </LinkTo>
                </li>

                {/* <li>
                  <LinkTo href="/repair" aria-label={t('MENU.REPAIR')}>
                    {t('MENU.REPAIR')}
                  </LinkTo>
                </li> */}

                <li>
                  <LinkTo href="/login" aria-label={t('MENU.LOGIN')}>
                    {t('MENU.LOGIN')}
                  </LinkTo>
                </li>

                <li>
                  <LinkTo href="/contacts" aria-label={t('MENU.CONTACTS')}>
                    {t('MENU.CONTACTS')}
                  </LinkTo>
                </li>
                {/* <li>
                  <LinkTo href="/faq">{t('MENU.FAQ')}</LinkTo>
                </li> */}
              </ul>
            </div>

            <div className="space-y-6">
              <h3 className="text-2xl font-medium tracking-uyn uppercase">
                {t('MENU.ABOUT')}
              </h3>

              <ul className="space-y-3">
                <li>
                  <LinkTo href="/about" aria-label={t('MENU.WHO_WE_ARE')}>
                    {t('MENU.WHO_WE_ARE')}
                  </LinkTo>
                </li>

                <li>
                  <LinkTo
                    href="/manufacturing"
                    aria-label={t('MENU.MANUFACTURING')}
                  >
                    {t('MENU.MANUFACTURING')}
                  </LinkTo>
                </li>

                <li>
                  <LinkTo
                    href="/second-life"
                    aria-label={t('MENU.SECOND_LIFE')}
                  >
                    {t('MENU.SECOND_LIFE')}
                  </LinkTo>
                </li>

                <li>
                  <LinkTo href="/airnest" aria-label={t('MENU.AIRNEST')}>
                    {t('MENU.AIRNEST')}
                  </LinkTo>
                </li>

                <li>
                  <LinkTo href="/awards" aria-label={t('MENU.AWARDS')}>
                    {t('MENU.AWARDS')}
                  </LinkTo>
                </li>

                <li>
                  <LinkTo href="/uynners" aria-label={t('MENU.UYNNERS')}>
                    {t('MENU.UYNNERS')}
                  </LinkTo>
                </li>

                <li>
                  <LinkTo
                    href="/work-with-us"
                    aria-label={t('MENU.WORK_WITH_US')}
                  >
                    {t('MENU.WORK_WITH_US')}
                  </LinkTo>
                </li>
                {country === 'us' && (
                  <li>
                    <LinkTo
                      href="/avantlink"
                      aria-label={t('MENU.AFFILIATE_PROGRAM')}
                    >
                      {t('MENU.AFFILIATE_PROGRAM')}
                    </LinkTo>
                  </li>
                )}
              </ul>
            </div>
          </div>

          <div className="text-white/70 space-y-8">
            <p>
              &copy; {new Date().getFullYear()} {t('UYN.COLOPHON')}
            </p>

            <div className="xl:flex xl:justify-between space-y-8 xl:space-y-0">
              <ul className="space-y-2 md:flex md:space-y-0 md:space-x-4">
                <li>
                  <LinkTo
                    href="/terms-and-conditions"
                    aria-label={t('MENU.TERMS_AND_CONDITIONS')}
                  >
                    {t('MENU.TERMS_AND_CONDITIONS')}
                  </LinkTo>
                </li>

                <li>
                  <LinkTo href="/imprint" aria-label={t('MENU.IMPRINT')}>
                    {t('MENU.IMPRINT')}
                  </LinkTo>
                </li>

                <li>
                  <a
                    href={`https://www.iubenda.com/privacy-policy/${t(
                      POLICY_ID
                    )}`}
                    className="iubenda-white no-brand iubenda-noiframe iub-body-embed"
                    title="Privacy Policy"
                    target="_blank"
                    rel="noreferrer"
                    aria-label={t('MENU.PRIVACY_POLICY')}
                  >
                    {t('MENU.PRIVACY_POLICY')}
                  </a>
                </li>

                <li>
                  <a
                    href={`https://www.iubenda.com/privacy-policy/${t(
                      POLICY_ID
                    )}/cookie-policy`}
                    className="iubenda-white no-brand iubenda-noiframe iub-body-embed"
                    title="Cookie Policy"
                    target="_blank"
                    rel="noreferrer"
                    aria-label={t('MENU.COOKIE_POLICY')}
                  >
                    {t('MENU.COOKIE_POLICY')}
                  </a>
                </li>

                <li>
                  <p>v. {packageInfo.version}</p>
                </li>

                <li>
                  Crafted by&nbsp;
                  <a
                    href="https://beconcept.studio/"
                    title="Credits"
                    target="_blank"
                    rel="noreferrer"
                    aria-label="Beconcept"
                  >
                    Beconcept
                  </a>
                </li>
              </ul>

              <div className="w-60">
                <Image src={payWithIcon} alt="Payment options" />
              </div>
            </div>
          </div>
        </div>
      </div>

      {showIubenda && (
        <>
          <script
            type="text/javascript"
            dangerouslySetInnerHTML={{
              __html: `
              var _iub = _iub || [];
              _iub.csConfiguration = {
                "countryDetection":true,
                "enableFadp":true,
                "enableUspr":true,
                "perPurposeConsent":true,
                "googleConsentMode": "template",
                "siteId":${t('IUBENDA.SITE_ID')},
                "whitelabel":false,
                "cookiePolicyId":${t(POLICY_ID)},
                "lang":"${t('IUBENDA.LANG')}", 
                "banner":{ 
                  "acceptButtonCaptionColor":"white",
                  "acceptButtonColor":"#1398c5",
                  "acceptButtonDisplay":true,
                  "backgroundColor":"white",
                  "closeButtonRejects":true,
                  "customizeButtonCaptionColor":"#4d4d4d",
                  "customizeButtonColor":"#eeeeee",
                  "customizeButtonDisplay":true,
                  "explicitWithdrawal":true,
                  "fontSizeBody":"12px",
                  "fontSizeCloseButton":"12px",
                  "listPurposes":true,
                  "position":"float-bottom-center",
                  "rejectButtonCaptionColor":"#4d4d4d",
                  "rejectButtonColor":"#eeeeee",
                  "rejectButtonDisplay":true,
                  "textColor":"black" 
                }
              };
                `
            }}
          />

          <script
            type="text/javascript"
            src="//cdn.iubenda.com/cs/iubenda_cs.js"
            async
          />

          {/* IUBENDA COOKIE policies */}
          <script
            type="text/javascript"
            dangerouslySetInnerHTML={{
              __html: `(function (w,d) {var loader = function () {var s = d.createElement("script"), tag = d.getElementsByTagName("script")[0]; s.src="https://cdn.iubenda.com/iubenda.js"; tag.parentNode.insertBefore(s,tag);}; if(w.addEventListener){w.addEventListener("load", loader, false);}else if(w.attachEvent){w.attachEvent("onload", loader);}else{w.onload = loader;}})(window, document);
                  `
            }}
          />
          {/* PRIVACY policies */}
          <script
            type="text/javascript"
            dangerouslySetInnerHTML={{
              __html: `(function (w,d) {var loader = function () {var s = d.createElement("script"), tag = d.getElementsByTagName("script")[0]; s.src="https://cdn.iubenda.com/iubenda.js"; tag.parentNode.insertBefore(s,tag);}; if(w.addEventListener){w.addEventListener("load", loader, false);}else if(w.attachEvent){w.attachEvent("onload", loader);}else{w.onload = loader;}})(window, document);
                  `
            }}
          />
        </>
      )}
      {injectAvantLink && (
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              `
              (function() {
                var avm =   document.createElement('script'); avm.type = 'text/javascript'; avm.async = true;
                avm.src = ('https:' == document.location.protocol ? 'https://' : 'http://') + 'cdn.avmws.com/` +
              process.env.NEXT_PUBLIC_AVANTLINK_ID +
              `/';
                var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(avm, s);
              })();`
          }}
        ></script>
      )}
    </footer>
  );
}
