import { useRouter } from 'next/router';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { fetchColors } from '../../fetchers/mapping';
import { fetchShippingCountriesList } from '../../fetchers/shipping';
import { getLangCountry } from '../../helpers/languages';
import { getCurrencyName } from '../../helpers/prices';
import usePrice from '../../hooks/usePrice';
import { ICartPrice, ICartProduct } from '../../models/cart';
import localizedRoute from '../../utils/localizedRoute';
import LinkTo from '../LinkTo';
import MiniSpinner from '../MiniSpinner';
import Image from 'next/future/image';
import { TrashIcon } from '@heroicons/react/outline';

const CartProduct = ({
  product,
  onChangeQty,
  onRemoveProduct
}: {
  product: ICartProduct;
  onChangeQty: (product: ICartProduct, qty: number) => Promise<void>;
  onRemoveProduct: (product: ICartProduct) => Promise<void>;
}) => {
  const [qty, setQty] = useState(product.quantity);
  const [formatPrice] = usePrice();
  const router = useRouter();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const colorsCodeToLabel = fetchColors();
  const { lang, country } = getLangCountry(router.query);
  const countries = fetchShippingCountriesList({ lang });
  const currencyCode = getCurrencyName(countries, country);

  const getColorLabel = (color_code: string) => {
    const { color = '' } = colorsCodeToLabel[color_code] || {};
    return color;
  };

  useEffect(() => {
    updateProduct();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [qty]);

  const updateProduct = async () => {
    if (product.quantity === qty) {
      return;
    }
    setLoading(true);
    await onChangeQty(product, qty);
    setLoading(false);
  };

  const ORIGINAL_PRICE =
    typeof product.price !== 'object'
      ? product.price
      : product.price?.price || 0;

  const SALE_PRICE =
    typeof product.price !== 'object' ? 0 : product.price?.sale_price || 0;

  const removeProduct = async () => {
    setLoading(true);
    await onRemoveProduct(product);
    setLoading(false);
    const value = SALE_PRICE
      ? SALE_PRICE * product.quantity
      : ORIGINAL_PRICE * product.quantity;
    window.dataLayer?.push({ ecommerce: null });
    window.dataLayer?.push({
      event: 'remove_from_cart',
      ecommerce: {
        currency: currencyCode,
        value: value,
        remove: {
          products: [
            {
              id: product['product-sku'],
              name: product.name,
              price: (product.price as ICartPrice)?.price,
              brand: 'UYN',
              category: '',
              quantity: product.quantity
            }
          ]
        }
      }
    });
  };

  function decrementQuantity() {
    setQty(qty => qty - 1);
  }

  function incrementQuantity() {
    setQty(qty => qty + 1);
  }

  return (
    <li className="flex items-start space-x-3 px-4 py-4 sm:px-12">
      <Image
        src={product.image}
        alt={product.name}
        width={0}
        height={0}
        sizes="100vw"
        className="w-28 h-auto object-contain"
      />

      <div className="flex-1 flex flex-col space-y-3 md:space-y-4">
        <div className="space-y-3 md:space-y-0">
          <h3 className="text-sm md:text-base">
            <LinkTo href={product.slug}>{product.name}</LinkTo>
          </h3>

          <p className="text-sm text-gray-500 md:text-base">
            <span>
              {t('SIZE')}: {product.size}
            </span>

            <span> - </span>

            <span>
              {t('COLOR')} : {getColorLabel(product.color)}
            </span>
          </p>

          {product.needs_eu_shipping && (
            <div className="mt-2 inline-flex text-sm text-gray-500 bg-gray-100 rounded-sm px-2 py-1">
              {/* {message} */}
              {t('CART_CUSTOMS')}
            </div>
          )}
        </div>

        <p className="font-bold md:hidden">
          {SALE_PRICE ? (
            <>
              <span className="line-through	text-gray-400">
                {formatPrice(ORIGINAL_PRICE)}
              </span>
              <span className="font-bold"> {formatPrice(SALE_PRICE)}</span>
            </>
          ) : (
            formatPrice(ORIGINAL_PRICE)
          )}
        </p>

        <div className="flex items-center space-x-3">
          <div className="h-10 flex items-center border rounded-uyn px-3">
            <button
              className="text-base"
              disabled={loading}
              onClick={decrementQuantity}
            >
              −
            </button>

            <span className="inline-block mx-4 text-base">{qty}</span>

            <button
              className="text-base"
              disabled={loading}
              onClick={incrementQuantity}
            >
              +
            </button>
          </div>

          <button onClick={removeProduct} className="flex">
            {loading ? (
              <MiniSpinner />
            ) : (
              <span className="flex space-x-1">
                <TrashIcon className="w-5 h-5" />
                <span className="underline">{t('REMOVE')}</span>
              </span>
            )}
          </button>
        </div>
      </div>

      <p className="hidden font-bold md:block">
        {SALE_PRICE ? (
          <>
            <span className="line-through	text-gray-400">
              {formatPrice(ORIGINAL_PRICE)}
            </span>
            <span className="font-bold"> {formatPrice(SALE_PRICE)}</span>
          </>
        ) : (
          formatPrice(ORIGINAL_PRICE)
        )}
      </p>
    </li>
  );
};

export default CartProduct;
