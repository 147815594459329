import React from 'react';

import { MenuItem } from '@/types/Menu';
import LinkTo from '@/components/LinkTo';

interface Props {
  item: MenuItem;
  toggleOpen: () => void;
}

const Collabs: React.FC<Props> = ({ item, toggleOpen }) => {
  return (
    <div className="space-y-6 xl:space-y-3">
      <h3 className="font-bold uppercase tracking-uyn">Collabs</h3>

      <ul className="space-y-3 xl:space-y-1.5">
        {item.collabs?.map((collab, idx) => (
          <li key={idx}>
            {collab.href && (
              <LinkTo
                href={collab.href}
                onClick={toggleOpen}
                className="xl:text-sm"
                aria-label={collab.name}
              >
                {collab.name}
              </LinkTo>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Collabs;
